﻿import { gsap } from 'gsap';

export class Navigation {




    constructor() {
        /* DESKTOP NAVIGATION START*/
        let d = document;
        ["focus", "mouseover"].forEach((item) => {

            if (d.querySelector(".dropdown a")) {
                d.querySelector(".dropdown a").addEventListener(item, (e) => {
                    e.currentTarget.classList.add("expanded");
                    d.querySelector(".dropdown-content").classList.add("open");
                    d.querySelector("#navCurtain").style.display = "block";
                });
            }
        });

        d.querySelectorAll(".stopper").forEach((elementItem) => {

            ["focus", "mouseover"].forEach((item) => {
                elementItem.addEventListener(item, (e) => {
                    d.querySelector(".dropdown a").classList.remove("expanded");

                    d.querySelector(".dropdown-content").classList.remove("open");
                    d.querySelector("#navCurtain").style.display = "";
                });
            });

        });
        /* DESKTOP NAVIGATION END*/

        /*MOBILE NAVIGATION START*/
        d.querySelector("#mNavBtn").addEventListener("click", (e) => {
            if (e.currentTarget.classList.contains("open")) {
                e.currentTarget.classList.remove("open");
                d.querySelector("#mNavShell").classList.remove("open");

                d.querySelector("body").style.overflow = "";
            } else {
                e.currentTarget.classList.add("open");
                d.querySelector("#mNavShell").classList.add("open");
                d.querySelector("body").style.overflow = "hidden";
               
            }
        });

        d.querySelector(".accordion .collapsed").addEventListener("click", (e) => {
            e.currentTarget.classList.toggle("active");
            var content = e.currentTarget.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
        /*MOBILE NAVIGATION END*/

        /*NAVIGATION MARQUEE START*/

        if (d.querySelector("#countdown")) {
            if (parseInt(getComputedStyle(d.querySelector("#countdown")).height) > 70) {
                d.querySelector("#countdown").classList.add("marquee");
            }

            window.addEventListener("resize", (e) => {
                if (parseInt(getComputedStyle(d.querySelector("#countdown")).height) > 70) {
                    d.querySelector("#countdown").classList.add("marquee");
                }
            });

            gsap.to(d.querySelector("#countdown"), .5, { opacity: 1 });
        }
        /*NAVIGATION MARQUEE END*/

    }
}

