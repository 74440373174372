﻿import './framework.less'
import { Navigation } from './navigation.js'
import { gsap, CSSPlugin, ScrollToPlugin } from 'gsap/all';
import axios from "axios";
const plugins = [CSSPlugin, ScrollToPlugin];
window.d = document;
window.gsap = gsap;
window.axios = axios;
gsap.registerPlugin(ScrollToPlugin);

if (document.readyState !== 'loading') {
    var nav = new Navigation();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        var nav = new Navigation();
    });
}